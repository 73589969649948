.wrap {
   margin: 5px 0;
   text-align: center;
}

.label {
   color: #fff;
   font-weight: 600;
   font-size: calc(16px + 2vmin);
}

.value {
   font-weight: 600;
   color: #fff;
   font-size: calc(24px + 2vmin);
}
