.card-wrapper {
   margin-top: 30px;
}
.wrap {
   padding: 5px 0;
}
.content {
   display: flex;
   align-items: center;
   justify-content: space-around;
   min-width: 220px;
}
.content img {
   width: 98px;
   position: inherit;
}

.content img::after {
   position: absolute;
   width: 48px;
   height: 48px;
   border-radius: 50%;
   background-color: #6085f7;
   color: #fff;
   top: -24px;
   left: -24px;
   content: attr(data-value);
}
.button-section {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.tools {
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: 20px;
}
.total {
   margin-top: 20px;
   font-weight: 600;
   text-align: center;
   font-size: calc(18px + 2vmin);
   border: 1px solid #6085f7;
   border-radius: 24px;
   background-color: rgb(112, 112, 112);
   color: #fff;
   padding: 5px 0;
}
.small {
   font-size: calc(8px + 0.8vmin);
   color: rgb(59, 58, 58);
}
.bnb {
   color: coral;
   font-size: 1.2rem;
}
