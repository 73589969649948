@keyframes spin {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}
.fu-progress {
   position: relative;
   width: 150px;
   height: 150px;
   border: 5px solid #178bca;
   border-radius: 50% !important;
}
.fu-progress .fu-inner {
   position: absolute;
   overflow: hidden;
   z-index: 2;
   width: 140px;
   height: 140px;
   border: 5px solid #ffffff;
   border-radius: 50% !important;
}
.fu-progress .fu-inner .water {
   position: absolute;
   z-index: 1;
   background: rgba(23, 139, 202, 0.5);
   width: 200%;
   height: 200%;
   transform: translateZ(0);
   -webkit-transform: translateZ(0);
   transition: all 1s ease !important;
   -webkit-transition: all 1s ease !important;
   top: 50%;
   left: -50%;
   border: 1px solid transparent;
   border-radius: 40% !important;
   animation-duration: 10s;
   animation-name: spin;
   animation-iteration-count: infinite;
   animation-timing-function: linear;
}
.fu-progress .fu-inner .glare {
   position: absolute;
   top: -120%;
   left: -120%;
   z-index: 5;
   width: 200%;
   height: 200%;
   transform: rotate(45deg);
   background: #ffffff;
   background: rgba(255, 255, 255, 0.2);
   border-radius: 50%;
}
.fu-progress .fu-inner .fu-percent {
   position: absolute;
   top: 36px;
   width: 100%;
   height: 100%;
   font-size: 44px;
   font-weight: bold;
   color: #045681;
   text-align: center;
}
