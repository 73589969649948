.box {
   position: relative;
   width: 100%;
   padding: 1rem;
   border: 1px solid white;
   border-radius: 10px;
   box-shadow: 0 0 20px 1px rgba(5, 5, 5, 0.1);
   /* margin: 40px auto; */
   max-width: 480px;
   background-color: rgba(0, 0, 0, 0.55);
   /*--tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
   box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow); */
   transition: 0.5s;
   background-size: 200% auto;
}

.gradient {
   background-color: #eaeaeaf2;
}

.header {
   position: relative;
   font-size: 1.125rem;
   line-height: 1.75rem;
   font-weight: 600;
   display: table;
   margin-bottom: 0.5rem;
}
.header::after {
   content: "";
   position: absolute;
   height: 2px;
   width: 100%;
   bottom: 0;
   left: 0;
   box-sizing: border-box;
   border-width: 0;
   background-color: #fff;
   /* background-image: linear-gradient(90deg, #2c69d1 0, #0abcf9 51%, #2c69d1); */
   transition: 0.5s;
   background-size: 200% auto;
}
