.item {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   clear: both;
}

.tools {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   justify-content: space-between;
   margin-top: 10px;
}
